<template>
  <div class="dust-battle">
    <CompHeader title="对战">
      <div class="comp-header-right-box">
        <div class="coin"></div>
      </div>
    </CompHeader>
    <div class="float-bottom-box">
      <div class="float-bottom-header flex-b">
        <div
          v-for="item in filterList"
          :key="item.value"
          class="filter-item flex-s">
          <div style="background: #332a19" class="rem-password-checkbox">
            <div v-show="activeFilter === item.value" class="float-gou-icon">
              <div class="bg-img"></div>
            </div>
          </div>
          <div style="color: #fff" class="rem-password-text">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="float-bottom-btns flex-b">
        <div class="btn btn1 flex-c">最佳对战</div>
        <div class="btn btn2 flex-c">历史记录</div>
        <div @click="$router.push('/createBattle')" class="float-btn flex-c">
          创建对战
        </div>
      </div>
    </div>
    <div class="battle-search-box flex-b">
      <div class="battle-search-left">
        <div class="input-box">
          <el-input
            maxlength="16"
            class="clear-input-style form-item-input"
            v-model="searchKeywords"
            placeholder="请输入想要搜索的对战名称"></el-input>
        </div>
        <div class="float-right-icon point"></div>
      </div>
      <div class="battle-search-right flex-b">
        <div class="text-box">201-800</div>
        <div class="icon-box"></div>
      </div>
    </div>
    <div class="battle-filter-box flex-s">
      <div v-for="item in 4" :key="item" class="filter-item flex-c">
        100-200
      </div>
    </div>
    <div class="battle-main-box">
      <div class="battle-main-header flex-b">
        <div class="main-left-filter-box flex-s">
          <div
            v-for="item in filterList"
            :key="item.value"
            class="filter-item flex-s">
            <div class="rem-password-checkbox">
              <div v-show="activeFilter === item.value" class="float-gou-icon">
                <div class="bg-img"></div>
              </div>
            </div>
            <div style="color: #fff" class="rem-password-text">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="main-right-tips-box flex-s">
          <div class="icon"></div>
          <div class="text">玩法介绍</div>
        </div>
      </div>
      <div class="battle-main-list-box flex-b">
        <div
          v-for="item in finalBattleList"
          :key="item.id"
          class="list-item"
          :class="{
            'list-item-ing': item.status === 1,
            'list-item-end': item.status === 2,
          }">
          <div class="item-header flex-b">
            <div style="color: #f64e37" class="left-text">
              {{ battleTypeList[item.type] }}
            </div>
            <div class="right-text">{{ item.box_num }}回合</div>
          </div>
          <div style="padding: 0 0.05rem" class="foo-wrap">
            <div class="item-name">{{ item.user_name }}</div>
            <div class="player-list flex-a">
              <div
                v-for="vItem in item.newSeatList"
                :key="vItem?.id"
                class="player-item">
                <div v-if="vItem" class="player-item-img">
                  <img
                    class="wh100"
                    :src="vItem?.user?.avatar"
                    alt=""
                    srcset="" />
                  <div class="float-bg-img"></div>
                </div>
                <div v-else class="player-item-img">
                  <img
                    class="wh100"
                    src="../../assets/newImages/join-battle-icon.png"
                    alt=""
                    srcset="" />
                </div>
              </div>
            </div>
            <div class="box-list flex-s">
              <div
                v-for="vItem in item.game_arena_box"
                :key="vItem.id"
                class="box-item">
                <img
                  class="wh100"
                  :src="vItem.box.intact_cover"
                  alt=""
                  srcset="" />
              </div>
            </div>
            <div class="join-box flex-b">
              <div class="left flex-s">
                <CompCoin
                  v-if="item.status === 0"
                  style="color: #fff"
                  :num="item.total_bean" />
                <CompCoin
                  v-if="item.status === 1"
                  style="color: #fff"
                  :num="item.total_bean" />
                <CompCoin
                  v-if="item.status === 2"
                  :styleList="[0.1, 0.1, 0.1, 0.02]"
                  :num="item.total_bean" />
              </div>
              <div v-if="item.status === 0" class="right-text">加入</div>
              <div
                v-if="item.status === 1"
                class="right-text"
                style="color: #fff">
                观战
              </div>
              <div v-if="item.status === 2" class="join-icon"></div>
              <CompCoin
                v-if="item.status === 2"
                :styleList="[0.1, 0.1, 0.1, 0.02]"
                :num="item.total_bean" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBattleRoomListApi } from "@/network/api.js";
export default {
  name: "DustBattle",
  data() {
    return {
      searchKeywords: "",
      filterList: [
        { name: "全部", value: "0" },
        { name: "玩家", value: "1" },
        { name: "主播", value: "2" },
      ],
      activeFilter: "1",
      battleTypeList: ["模式", "欧皇", "非酋"],
      battleList: [],
    };
  },
  created() {
    this.init();
  },
  computed: {
    finalBattleList() {
      return (this.battleList || []).map((wrapItem) => {
        let newSeatList = new Array(wrapItem.user_num).fill(null);
        newSeatList = newSeatList.map((wItem, index) => {
          let seatItem = wItem;
          wrapItem.game_arena_player.forEach((vItem) => {
            console.log(123, vItem);

            if (vItem.seat === index) {
              seatItem = vItem;
            }
          });
          return seatItem;
        });
        console.log(111111, {
          ...wrapItem,
          newSeatList,
        });

        return {
          ...wrapItem,
          newSeatList,
          status: 0,
        };
      });
    },
  },
  methods: {
    init() {
      this.getBattleRoomListFun();
    },
    async getBattleRoomListFun() {
      try {
        //状态 0:等待(默认) 1:进行中 2:已结束
        const params = {
          page: 1,
          status: 0,
        };
        const res = await getBattleRoomListApi(params);
        console.log("对战列表查询", res);
        if (res?.data?.code === 200) {
          this.battleList = res?.data?.data?.data;
          console.log("对战列表", this.battleList);
        } else {
          this.$message.error("对战列表查询失败");
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.dust-battle {
  background: #363022;
  min-height: 100vh;
  padding: 0.58rem 0rem 0.9rem;
  .comp-header-right-box {
    position: absolute;
    right: 0.2rem;
    top: 0.13rem;
  }
  .float-bottom-box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.9rem;
    padding: 0.12rem 0.2rem 0.08rem;
    background: #4f4424;
    .float-bottom-header {
      margin-bottom: 0.16rem;
    }
    .float-bottom-btns {
      position: relative;
      .btn {
        width: 0.86rem;
        height: 0.26rem;
        font-size: 0.12rem;
        background: #33211a;
      }
      .float-btn {
        width: 1.4rem;
        height: 0.4rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-weight: 500;
        font-size: 0.16rem;
        color: #312f2d;
        @include bgImg("create-battle-bg");
      }
    }
  }

  .battle-search-box {
    margin-bottom: 0.12rem;
    padding: 0 0.12rem;
    .battle-search-left {
      width: 2.45rem;
      height: 0.22rem;
      background: rgba(42, 27, 0, 0.6);
      position: relative;
      .input-box {
        flex: 1;
        height: 100%;
        border: 0.01rem solid #817043;
        .form-item-input {
          .el-input__inner {
            font-size: 0.12rem;
            &::placeholder {
              font-size: 0.12rem;
              color: #a4a4a4;
            }
          }
        }
      }
      .float-right-icon {
        position: absolute;
        right: 0;
        top: 0;
        width: 0.46rem;
        height: 100%;
        @include bgImg("search-icon-plus");
      }
    }
    .battle-search-right {
      width: 0.86rem;
      height: 0.22rem;
      background: rgba(42, 27, 0, 0.6);
      border: 0.01rem solid #817043;
      position: relative;
      padding: 0.05rem 0.09rem;
      //   @include bgImg("blind-btn-bg");
      .text-box {
        font-size: 0.12rem;
        color: #ffe194;
      }
      .icon-box {
        width: 0.03rem;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          border-left: 0.045rem solid transparent;
          border-right: 0.045rem solid transparent;
          border-top: 0.06rem solid #ffe194; /* 箭头的颜色 */
          right: 0rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .battle-filter-box {
    margin-bottom: 0.12rem;
    padding: 0 0.2rem;
    .filter-item {
      width: 0.71rem;
      height: 0.3rem;
      background: #69552d;
      font-size: 0.12rem;
      color: #a89265;
      margin-right: 0.17rem;
      flex-shrink: 0;
    }
  }
  .battle-main-box {
    padding: 0.09rem 0.2rem;
    .battle-main-header {
      margin-bottom: 0.08rem;
      .main-left-filter-box {
        flex: 1;
        .filter-item {
          margin-right: 0.17rem;
        }
      }
      .main-right-tips-box {
        .icon {
          width: 0.14rem;
          height: 0.14rem;
          margin-right: 0.08rem;
          @include bgImg("tips-icon");
        }
        .text {
          font-size: 0.12rem;
          color: #ffedd1;
        }
      }
    }
    .battle-main-list-box {
      flex-wrap: wrap;

      // 等待中
      .list-item {
        width: 1.6rem;
        min-height: 1rem;
        background: #69552d;
        margin-bottom: 0.15rem;
        flex-shrink: 0;
        padding: 0rem 0 0.05rem 0;
        @include bgImg("battle-item-bg");
        .item-header {
          padding: 0 0.08rem;
          width: 100%;
          height: 0.24rem;
          font-size: 0.12rem;
          color: #ffe194;
          line-height: 0.24;
          margin-bottom: 0.03rem;
          @include bgImg("battle-item-bg-top");
        }
        .item-name {
          width: 100%;
          height: 0.29rem;
          line-height: 0.29rem;
          text-align: center;
          font-weight: 500;
          font-size: 0.14rem;
          margin-bottom: 0.08rem;
          @include bgImg("name-bg");
        }
        .player-list {
          margin-bottom: 0.08rem;
          .player-item {
            width: 0.36rem;
            height: 0.36rem;
            border-radius: 50%;
            overflow: hidden;
            // @include bgImg("search-icon-plus");
            .player-item-img {
              position: relative;
              .float-bg-img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include bgImg("head-img-bg");
              }
            }
          }
        }
        .box-list {
          padding: 0.02rem 0.05rem;
          margin-bottom: 0.12rem;
          background: #332a19;
          border: 0.01rem solid rgba(143, 108, 60, 0.3);
          overflow-x: auto;
          .box-item {
            width: 0.44rem;
            height: 0.44rem;
            margin-right: 0.04rem;
            flex-shrink: 0;
            @include bgImg("search-icon-plus");
          }
        }
        .join-box {
          width: 100%;
          height: 0.3rem;
          font-weight: 500;
          font-size: 0.14rem;
          color: #fae277;
          padding: 0.08rem;
          @include bgImg("join-bg");
          .join-icon {
            width: 0.24rem;
            height: 0.24rem;
            @include bgImg("join-icon");
          }
        }
      }

      // 进行中
      .list-item-ing {
        .join-box {
          @include bgImg("see-bg");
        }
      }

      // 已结束
      .list-item-end {
        .join-box {
          @include bgImg("end-bg");
        }
      }
    }
  }
}
</style>
